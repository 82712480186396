exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leistungen-coaching-js": () => import("./../../../src/pages/leistungen/coaching.js" /* webpackChunkName: "component---src-pages-leistungen-coaching-js" */),
  "component---src-pages-leistungen-konfliktberatung-js": () => import("./../../../src/pages/leistungen/konfliktberatung.js" /* webpackChunkName: "component---src-pages-leistungen-konfliktberatung-js" */),
  "component---src-pages-leistungen-mediation-js": () => import("./../../../src/pages/leistungen/mediation.js" /* webpackChunkName: "component---src-pages-leistungen-mediation-js" */),
  "component---src-pages-leistungen-seminarangebote-js": () => import("./../../../src/pages/leistungen/seminarangebote.js" /* webpackChunkName: "component---src-pages-leistungen-seminarangebote-js" */),
  "component---src-pages-leistungen-supervision-js": () => import("./../../../src/pages/leistungen/supervision.js" /* webpackChunkName: "component---src-pages-leistungen-supervision-js" */),
  "component---src-pages-nachricht-gesendet-js": () => import("./../../../src/pages/nachricht-gesendet.js" /* webpackChunkName: "component---src-pages-nachricht-gesendet-js" */),
  "component---src-pages-philosophie-js": () => import("./../../../src/pages/philosophie.js" /* webpackChunkName: "component---src-pages-philosophie-js" */)
}

